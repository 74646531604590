<template>
  <b-card title="İndirim Kodu Doğrulama">
    <b-row>
      <b-col
        md="6"
        offset-md="3"
      >
        <b-form-group
          label="İndirim Kodu"
          label-for="coupon_code"
        >
          <b-input-group>
            <b-form-input
              id="coupon_code"
              v-model="code"
              placeholder="İndirim Kodu..."
              @keydown.enter="getData"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="getData"
              >
                Ara
              </b-button>
              <b-button
                v-if="code"
                variant="warning"
                @click="code = null"
              >
                <FeatherIcon icon="XIcon" /> Temizle
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-alert
          :show="itemData.error"
          variant="danger"
        >
          <div class="alert-body text-center">
            İndirim kodu hatalı/geçersiz veya daha önceden kullanılmış.
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'Search',
  components: {
    BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BAlert,
  },
  data() {
    return {
      code: null,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['serviceCoupons/getCoupon']
    },
    serviceTracing() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('serviceCoupons/coupon', { code: this.code, id_com_cars: this.serviceTracing.id_com_cars })
    },
  },
}
</script>
